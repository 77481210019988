
/* global syling*/

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: $mainFont2, arial, sans-serif;
  @include zeroOut;
}

h1,
h2,
h3,
h4 {
  font-family: $accentFont2, cursive;
}

.wrapper {
  background-color: transparentize($compNeutral, .80%);
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

.headerStyle {
  @include displayStyle;
  background-color: $compBaseNeutral;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1em;
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.6));
}

header {
    flex-basis: 35%;
}

header h1{
  font-size: var(--accentFontSize);
  margin-left: .5em;
}

nav {
  font-size: var(--navFontSize);
  flex-basis: 45%;
    ul {
      
      list-style-type: none;
      text-align: center;
    }

    li {
      display: inline-block;
      padding-right: .2em;
    }

    a {
      color: darken($compBaseComp, .8);
      text-decoration: none;
        &:hover {
          color: $compShade;
        }
    }
}

main {
 @include displayStyle;
  width: 96%;
  max-width: 1500px;
  margin: 0 auto;
  flex-direction: row;
  justify-content: center;
}

footer {
  text-align: center;
  background-color: $compBaseNeutral;
  width: 100%;
     p {
      @include zeroOut;
      font-size: .8em;
    }
}

.smImage {
   @include imageDisplay; 
}

.buttonAttn input{
    background-image: radial-gradient($compTint, $compBaseComp,  $compShade);
    border-radius: 1em;
    font-size: var(--navFontSize);
    text-align: center;
    padding: 1em; 
    font-family: $mainFont2, arial, sans-serif;
    color: darken($compShade, 25);
    text-decoration: none;
    border-style: ridge;
    border-color: $compBase;
    margin-top: 1em;
    filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.9));
   
}

/*documentation styles*/

.paletteWrap {
    @include displayStyle;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 1em;
}

.swatch {
    flex-basis: 23%;
    padding: 1em;
}

.comp1 {
    background-color: $compBase;
    color: $compTint;
}

.comp2 {
    background-color: $compBaseComp;
}

.comp3 {
    background-color: $compBaseNeutral;
}

.comp4{
    background-color: $compNeutral;
    color: $compTint;
}

.comp5 {
    background-color: $compBaseShade;
    color: $compTint;
}

.comp6 {
    background-color: $compShade;
}

.comp7 {
    background-color: $baseTint;
}

.comp8 {
    background-color: $compTint;
}

.near1 {
    background-color: $nearBase;
    color: $leftTint;
}

.near2 {
    background-color: $nearShade;
    color: $leftTint;
}

.near3 {
    background-color: $nearTint;
}

.near4 {
    background-color: $rightBase;
    color: $leftTint;
}

.near5 {
    background-color: $leftBase;
    color: $leftTint;
}

.near6 {
    background-color: $rightTint;
}

.near7 {
    background-color: $leftTint;
}

.wrapperDesign {
  background-color: transparentize($leftTint, .2);
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

.headerDesign {
  @include displayStyle;
  background-color: $nearBase;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1em;
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.6));
}

.footerDesign {
    background-color: $nearBase;
    color: $leftTint;
}

.docNav a {
      color: darken($compBaseShade, 25);
        &:hover {
          color: $compShade;
        }
    }

.designNav a{
      color: $leftTint;
        &:hover {
          color: $nearTint;
        }
    }

.accentDemo {
    font-family: $accentFont, cursive;
}

.fontDemo p {
    font-family: $mainFont, arial, sans-serif;
}


/*media queries*/

//media queries for typography and navigation

// CSS variables
:root {
    --accentFontSize: 3.5em;
    --navFontSize: 1.8em;
}

@media (max-width: 1024px) {
    
  :root {
    --accentFontSize: 3em;
    --navFontSize: 1.5em;
}
    
  header{
     flex-basis: 30%; 
    }   
   
  nav {
    flex-basis: 40%; 
    } 

    .headerStyle {
        justify-content: space-around;
    }
    
    .swatch {
        flex-basis: 46%
    }

}

@media (max-width: 768px) {
    :root {
        --accentFontSize: 2.5em;
        --navFontSize: 1em;
    }  
   
  header{
     flex-basis: 90%; 
     text-align: center; 
    }   
   
  nav {
    flex-basis: 90%; 
    text-align: center;  
    }
    
    .swatch {
        flex-basis: 90%;
    }

}
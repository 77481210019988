//card styling

//for designs using the complementary color palette
.plainCard {
    background-color: transparentize($compBase, .95); 
    border-radius: 1em;
    border-style: inset;
    border-color: $compBase;
}

.paddedCard {
    background-color: transparentize($compBase, .95); 
    padding: .5em;
    border-radius: 1em;
    border-style: inset;
    border-color: $compBase;
}

.animatedCard:hover {
   transition: all 1s;
   transform: scale(1.1, 1.1) translate(1em, 1em);
   background-color: transparentize($compBase, .85); ;
   border-radius: 10px;
   filter: drop-shadow(10px 10px 23px rgba(0, 0, 0, 0.9));
   border-style: inset;
   border-color: $compBase;
}


// for designs using the analogous color palette
.plainCardAP {
    background-color: transparentize($nearBase, .95); 
    border-radius: 1em;
    border-style: inset;
    border-color: $nearBase;
}

.paddedCardAP {
    background-color: transparentize($nearBase, .95); 
    padding: .5em;
    border-radius: 1em;
    border-style: inset;
    border-color: $nearBase;
}

.animatedCardAP:hover{
    transition: all 1s;
    transform: scale(1.1, 1.1) translate(1em, 1em);
    background-color: transparentize($nearBase, .85); ;
    border-radius: 10px;
    filter: drop-shadow(10px 10px 23px rgba(0, 0, 0, 0.9));
    border-style: inset;
    border-color: $nearBase;    

}
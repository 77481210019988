// mixins
@mixin displayStyle {
      display: flex; 
      flex-flow: wrap;
}

@mixin zeroOut {
      margin: 0;
      padding: 0;
}

//for thumbnail image display
@mixin imageDisplay {
      float: left;
      width: 100%;
      max-width: 200px;
      height: 150px;
      border-radius: .75em;
      margin-right: 1em;
      margin-bottom: 1em;   
    }



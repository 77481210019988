//media queries for grid system

//default sizes
:root {
    --flexSingleCol: 15%;
    --flexDoubleCol: 30%;
    --flexTripleCol: 45%;
    --flexFourCol: 60%;
    --flexFiveCol: 75%;
    --flexSixCol: 90%;    
}

@media (max-width: 1024px) {
   
 :root{
    --flexSingleCol: 45%;
    --flexDoubleCol: 45%;
    --flexFourCol: 90%;
    --flexFiveCol: 90%;
  } 
}
    
@media (max-width: 768px) {
    
  :root{
        --flexSingleCol: 90%;
        --flexDoubleCol: 90%;
        --flexTripleCol: 90%;
        --flexFourCol: 90%;
        --flexFiveCol: 90%;
  }  
}


/*grid layout*/

.row {
 @include displayStyle;
  justify-content: space-around;
}

.col-1-6 {
  flex-basis: var(--flexSingleCol);
}

.col-2-6 {
  flex-basis: var(--flexDoubleCol);
}

.col-3-6 {
    flex-basis: var(--flexTripleCol);
}

.col-4-6 {
    flex-basis: var(--flexFourCol);
}

.col-5-6 {
    flex-basis: var(--flexFiveCol);
}

.col-6-6 {
    flex-basis: var(--flexSixCol);
}
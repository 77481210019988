//color palettes

// Complementary Color Palette

// to change your primary color change the hsl value in the compBase variable
$compBase: hsl(235, 78%, 56%);

// these variable do not need to be changed, they will adjust according to the compBase
//this is the complement of the base color
$compBaseComp: complement($compBase);
//mix of the base color and it's complement, create a neutral tone weighted towards the base color
$compBaseNeutral: mix($compBase, $compBaseComp, 60%);
//mix of the base color and it's complement, create a neutral tone weighted towards the complementary color
$compNeutral: mix($compBaseComp, $compBase, 65%);
//this is a darker shade of the base color
$compBaseShade: adjust-color($compBase, $hue: -30%, $lightness: -30%);
//shade of the complementary color
$compShade: adjust-color($compBaseComp, $hue: -10%, $lightness: -10%);
//tint of the base color
$baseTint: lighten($compBase, 35%);
//tint of the complementary color
$compTint: lighten($compBaseComp, 35%);


//Analogous Color Palette
//Base color, change this value and the rest of the palette will adjust
$nearBase: hsl(345, 87%, 30%);
//darker shade of the base color
$nearShade: darken($nearBase, 5%);
//lighter tint of the base color
$nearTint: lighten($nearBase, 35%);
//color adjacent to the base color on the color wheel
$rightBase: adjust-color($nearBase, $hue: -30%, $lightness: -10%);
//color on the other side of the base color on the color wheel
$leftBase: adjust-color($nearBase, $hue: 30%, $lightness: -10%);
//tints of the analogous colors
$rightTint: lighten($rightBase, 25%);
$leftTint: lighten($leftBase, 65%);